import { SerializedError } from '@reduxjs/toolkit';
import { Blueprint } from '../../repository/models/Blueprint';
import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Attribute, Entity } from '../../repository/models/Entity';
import { HalForm, resolveTemplate, RForm, WithoutHal } from '../../hal';
import { useDeleteAttributeMutation, usePatchAttributeMutation } from './api';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { inheritHalProperty } from '../../hal/forms/react';
import Actions from '../../ui/action/Actions';
import Action from '../../ui/action/Action';
import DeleteButton from '../../ui/button/DeleteButton';
import PartiallyEditableTitleInput from '../../ui/input/PartiallyEditableTitleInput';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';
import EditableDescriptionInput from '../../ui/input/EditableDescriptionInput';
import AttributeConstraintsModel from './AttributeConstraintsModel';
import AttributeSearchOptionsModel from './AttributeSearchoptionsModel';

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);
const typeNames: Record<string, string> = {
    STRING: 'Text',
    LONG: 'Integer',
    DOUBLE: 'Decimal',
    DATETIME: 'Date',
    BOOLEAN: 'Boolean',
    CONTENT: 'Content',
    AUDIT_METADATA: 'Audit Metadata',
};

interface AttributeModelFormProps {
    currentBlueprint: Blueprint;
    entity: Entity;
    attribute: Attribute;
    form: HalForm<Attribute>;
    isSaving: boolean;
    error: SerializedError | undefined;
    onSave: (data: Partial<WithoutHal<Attribute>>) => void;
    onRename: (data: Attribute) => void;
    onCancel: () => void;
    onDelete: () => void;
}

function useEditableProp(originalValue: string) {
    const [state, setState] = useState<string | null>(null);

    return {
        editing: state !== null,
        value: state ?? originalValue,
        onEditRequest: () => setState(originalValue),
        onEditCancel: () => setState(null),
        onChange: (value: string) => setState(value),
    };
}

export default function AttributeModelForm(props: AttributeModelFormProps) {
    const deleteTemplate = resolveTemplate(props.attribute, 'delete');

    const [deleteAttribute, { isLoading: deleteLoading, error: deleteError, reset: deleteReset }] =
        useDeleteAttributeMutation();

    const [renameAttribute, { isLoading: renameLoading, error: renameError, reset: renameReset }] =
        usePatchAttributeMutation();
    const [
        updateDescription,
        { isLoading: updateDescriptionLoading, error: updateDescriptionError, reset: updateDescriptionReset },
    ] = usePatchAttributeMutation();

    const nameProp = useEditableProp(props.attribute.name);
    const descriptionProp = useEditableProp(props.attribute.description ?? '');

    return (
        <>
            <ServerErrorMessage error={renameError} />
            <RForm template={props.form}>
                <PartiallyEditableTitleInput
                    prefix="Attribute"
                    variant="h5"
                    editActionButtonText="Rename"
                    editing={nameProp.editing}
                    value={nameProp.value}
                    onEditRequest={nameProp.onEditRequest}
                    onEditCancel={() => {
                        nameProp.onEditCancel();
                        renameReset();
                    }}
                    onEditSave={async (name) => {
                        const response = await renameAttribute({
                            attribute: props.attribute,
                            patch: { name },
                        }).unwrap();
                        nameProp.onEditCancel();
                        props.onRename(response);
                    }}
                    editIsSaving={renameLoading}
                    TextInput={({ onChange, onEnterPressed, ...props }) => (
                        <HalValidatedTextInput
                            {...props}
                            name="name"
                            displayName="name"
                            overrideRegexMessage="Only lowercase alphanumeric and underscores are allowed."
                            placeholder="my_attribute"
                            handleOnChange={onChange}
                            handleOnKeyDown={onEnterPressed ? (key) => key === 'Enter' && onEnterPressed() : undefined}
                        />
                    )}
                />
                <Typography color="textSecondary">
                    Type: {typeNames[props.attribute.type] ?? props.attribute.type}
                </Typography>
                <EditableDescriptionInput
                    originalValue={props.attribute.description ?? ''}
                    value={descriptionProp.value}
                    onChange={descriptionProp.onChange}
                    editing={descriptionProp.editing}
                    onEditRequest={descriptionProp.onEditRequest}
                    onEditCancel={() => {
                        descriptionProp.onEditCancel();
                        updateDescriptionReset();
                    }}
                    editIsSaving={updateDescriptionLoading}
                    editSaveError={updateDescriptionError}
                    onEditSave={async (description) => {
                        await updateDescription({
                            attribute: props.attribute,
                            patch: { description },
                        }).unwrap();
                        descriptionProp.onEditCancel();
                    }}
                />
                <Box marginTop={2} marginBottom={1}>
                    <Typography variant="h6">Search</Typography>
                </Box>
                <AttributeSearchOptionsModel attribute={props.attribute} />
                <Box marginTop={2} marginBottom={1}>
                    <Typography variant="h6">Constraints</Typography>
                </Box>
                <AttributeConstraintsModel attribute={props.attribute} />
            </RForm>

            <Actions title="Delete this attribute">
                {deleteTemplate ? (
                    <Action description="You can delete this attribute.">
                        <DeleteButton
                            variant="contained"
                            onDeleteConfirm={async () => {
                                await deleteAttribute(props.attribute).unwrap();
                                props.onDelete();
                            }}
                            onDeleteCancel={deleteReset}
                            isLoading={deleteLoading}
                            error={deleteError}
                            dialogTitle={<>Delete attribute {props.attribute.name}</>}
                        >
                            <Typography>
                                Are you sure you want to delete attribute {props.attribute.name} on entity{' '}
                                {props.entity.name}?
                            </Typography>
                            <Typography>All data stored in this attribute will be lost.</Typography>
                        </DeleteButton>
                    </Action>
                ) : null}
            </Actions>
        </>
    );
}
