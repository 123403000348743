import { Box, Button, Typography } from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { NavLink, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { Edit } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { IamRealm } from '../../../repository/models/IamRealm';
import { useGetRealmUserByIdQuery, useGetRealmUserGroupsQuery } from '../api';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';
import { IamTypographyLabel } from '../../../ui/table/IamTable';
import { Overview } from '../../../ui/Overview';
import { GroupsTable } from './components/GroupsTable';
import { GroupsDialog } from './components/GroupsDialog';
import { IAM_REALM_USERS } from '../../routes';
import { Organization } from '../../../repository/models/Organization';
import BackLink from '../components/BackLink';
import { resolveTemplate } from '../../../hal';
import { IAM_REALM_EDIT_USER } from '../../routes';
import Actions from '../../../ui/action/Actions';
import Action from '../../../ui/action/Action';
import { ResetPasswordDialog } from './components/ResetPasswordDialog';

interface IamRealmUserProps {
    realm?: IamRealm;
    org: Organization | null;
}

export const IamRealmUser = ({ org, realm }: IamRealmUserProps) => {
    const { user: userId } = useParams();
    const userQueryParam = realm === undefined || userId === undefined ? skipToken : { realm, userId };
    const { data: user, isLoading, error } = useGetRealmUserByIdQuery(userQueryParam);
    const [opened, setOpened] = useState(false);
    const [resetOpened, setResetOpened] = useState(false);
    const { data: groupList, isLoading: isGroupsLoading, error: groupsError } = useGetRealmUserGroupsQuery(user!);

    const userTemplate = useMemo(() => {
        if (!!user) {
            return resolveTemplate(user, 'default');
        }
        return null;
    }, [user]);

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    if (!user) {
        return <Alert severity="error">User not found!</Alert>;
    }

    const resetTemplate = resolveTemplate(user, 'reset-password');

    return (
        <>
            <Box marginBottom={2}>
                <BackLink
                    to={IAM_REALM_USERS.generate({
                        org: org?.name!,
                        realm: realm?.id!,
                    })}
                >
                    Back to list
                </BackLink>
            </Box>

            {!user.enabled ? (
                <Box marginBottom={2}>
                    <Alert severity="warning">This user is deactivated, they can not log in.</Alert>
                </Box>
            ) : null}

            <Box display="flex">
                <Box flex="1 0">
                    <IamTypographyLabel label="Username" text={user.username} />
                    <IamTypographyLabel label="First name" text={user.firstName ?? '-'} />
                    <IamTypographyLabel label="Last name" text={user.lastName ?? '-'} />
                    <IamTypographyLabel label="Email" text={user.email ?? '-'} />
                    <IamTypographyLabel label="Created" text={new Date(user.created).toLocaleString()} />
                </Box>

                {!!userTemplate ? (
                    <Box minWidth={160} textAlign="right">
                        <Button
                            variant="contained"
                            color="primary"
                            component={NavLink}
                            to={IAM_REALM_EDIT_USER.generate({
                                org: org?.name!,
                                realm: realm?.id!,
                                user: userId!,
                            })}
                        >
                            <Edit fontSize="inherit" />
                            &nbsp; Edit user
                        </Button>
                    </Box>
                ) : null}
            </Box>

            <Overview>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                    <Typography>Groups</Typography>

                    <Button color="primary" variant="contained" onClick={() => setOpened(true)}>
                        Add group
                    </Button>
                </Box>

                <GroupsTable
                    list={groupList!}
                    user={user}
                    isLoading={isGroupsLoading}
                    error={groupsError!}
                    org={org}
                    realm={realm}
                />
            </Overview>

            {!!resetTemplate ? (
                <Actions>
                    <Action description={`Send password reset link to ${user.username}.`}>
                        <Button variant="outlined" color="primary" onClick={() => setResetOpened(true)}>
                            Reset password
                        </Button>
                    </Action>
                </Actions>
            ) : null}

            <ResetPasswordDialog user={user} opened={resetOpened} onClose={() => setResetOpened(false)} />

            <GroupsDialog
                user={user}
                opened={opened}
                realm={realm}
                list={groupList ?? null}
                onClose={() => setOpened(false)}
            />
        </>
    );
};
